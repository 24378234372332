import { FC } from "react";

type Props = {
    width?: number;
    height?: number;
    fill?: string;
};
const Close: FC<Props> = ({ width, height, fill, ...Props }) => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...Props}>
            <path d="M8 8L15 15" stroke={`${fill ? fill : '#D0D0D0'}`} strokeWidth="1.5" strokeLinecap="round" />
            <path d="M15 8L8 15" stroke={`${fill ? fill : '#D0D0D0'}`} strokeWidth="1.5" strokeLinecap="round" />
            <circle cx="11.5" cy="11.5" r="11" stroke={`${fill ? fill : '#D0D0D0'}`} />
        </svg>
    )
};
export default Close;

