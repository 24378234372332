import { useState } from 'react';
import s from './BannerHome.module.css';
import Link from 'next/link';
import Close from '@components/ui/icons/Close';
import Whatsapp from '@components/ui/icons/Whatsapp';
type Props = {

};
export function BannerHome(props: Props) {
    const [stateBanner, setStateBanner] = useState(true);

    const handleBanner = () => {
        setStateBanner(false);
    }

    return (
        <>
            {stateBanner &&
                <div className={`${s.content}`}>
                    <div className={`${s.container}`}>
                        <div className={`${s.content__Text}`}>
                            <Whatsapp width={25} height={25} fill='white'/>
                            <a className='ml-3' href="https://whatsapp.com/channel/0029VacwBIPBKfi0LVgfgN0M" target="_blank" rel="noreferrer">
                                Síguenos en nuestra comunidad de Whatsapp »
                            </a>
                        </div>
                    </div>
                    <button onClick={handleBanner} aria-label='Boton cerrar'>
                        <Close fill='white'/>
                    </button>
                </div>
            }
        </>
    );
};

export default BannerHome;