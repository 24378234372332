import { useState } from 'react';
import s from './BannerEnVivo.module.css';
import Link from 'next/link';
import IconClose from '@components/ui/icons/IconClose';
import Close from '@components/ui/icons/Close';
type Props = {

};
export function BannerEnVivo(props: Props) {
    const [stateBanner, setStateBanner] = useState(true);

    const handleBanner = () => {
        setStateBanner(false);
    }

    return (
        <>
            {stateBanner &&
                <div className={`${s.content}`}>
                    <div className={`${s.container}`}>
                        <span className={`${s.twinkle} ${s.alert_envivo}`}></span>
                        <div className={`${s.content__Text}`}>
                            <Link href="/envivo?ref=cintillo">
                                En vivo del precio del dólar »
                            </Link>
                        </div>
                    </div>
                    <button onClick={handleBanner} aria-label='Boton cerrar'>
                        <Close/>
                    </button>
                </div>
            }
        </>
    );
};

export default BannerEnVivo;